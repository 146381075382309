<template>
    <div class="page_content">
        <div class="page_edit_content page_detail">
            <a-descriptions :column="2">
                <a-descriptions-item label="配送时间">
                    {{ detail.create_time }}
                </a-descriptions-item>
                <a-descriptions-item label="配送人">
                    {{ detail.username }}
                </a-descriptions-item>
                <a-descriptions-item label="部门">
                    {{ detail.department_name }}
                </a-descriptions-item>
                <a-descriptions-item label="岗位职务">
                    {{ detail.role_name }}
                </a-descriptions-item>
				<a-descriptions-item label="客户">
                    {{ detail.customer_name }}
                </a-descriptions-item>

				<a-descriptions-item label="联系方式">
                    {{ detail.phone }}
                </a-descriptions-item>

				<a-descriptions-item label="门店地址">
                    {{ detail.address }}
                </a-descriptions-item>

				<a-descriptions-item label="附加项用途说明">
                    {{ detail.explain }}
                </a-descriptions-item>

				<a-descriptions-item :span="2" label="客户手签">
                    <!-- <LookImages :list=""></LookImages> -->
                </a-descriptions-item>

				<a-descriptions-item :span="2" label="客户手签日期">
                    {{ detail.customer_time }}
                </a-descriptions-item>

                <a-descriptions-item :span="2" label="物料">
                    <a-table
                        class="mt10"
                        :columns="columns"
                        :pagination="false"
                        :dataSource="detail.goods_data"
                    >
                    </a-table>
                </a-descriptions-item>
            </a-descriptions>

            
        </div>
    </div>
</template>

<script>
import LookImages from "@/components/LookImages";
import { costAddDetail } from '@/api/cost'
const columns = [
    {
        title: "品项",
        dataIndex: "goods_title",
    },
    {
        title: "数量",
        dataIndex: "num",
    },
];
export default {
    components: {
        LookImages
    },
    data() {
        return {
            columns,
            detail: {
                goods_data: []
            }
        }
    },
    created() {
        this.get_info()
    },
    methods: {
        get_info() {
            costAddDetail({
                data: {
                    id: this.$route.query.id,
                }
            }).then(res => {
                this.detail = res.data.list
            })
        }
    }
}
</script>

<style>
</style>